import { ISetting } from '@/models/settings.model';
import { GeneralSettingsBulkFormFields, GeneralSettingsFormFields, UISettingsFormFields } from './types';
import { groupBy } from 'lodash';

export const mapUIInitialFormValues = ({ theme, images }: ISetting) => {
  const { emptyStateLogo, footerLogo, headerLogo, mobileHeaderLogo, companyLogo, favicon48, favicon96, favicon144 } =
    groupBy(images, 'metaType');
  return {
    [UISettingsFormFields.COLOR_ON_PRIMARY]: theme?.colorOnPrimary,
    [UISettingsFormFields.COLOR_ON_SECONDARY]: theme?.colorOnSecondary,
    [UISettingsFormFields.DISABLED_COLOR]: theme?.disabledPrimaryColor,
    [UISettingsFormFields.PRIMARY_COLOR]: theme?.primaryColor,
    [UISettingsFormFields.PRIMARY_HOVER_COLOR]: theme?.primaryHoverColor,
    [UISettingsFormFields.SECONDARY_COLOR]: theme?.secondaryColor,
    [UISettingsFormFields.EMPTY_STATE_LOGO]: emptyStateLogo?.at(0)?.file?.url,
    [UISettingsFormFields.FOOTER_LOGO]: footerLogo?.at(0)?.file?.url,
    [UISettingsFormFields.HEADER_LOGO]: headerLogo?.at(0)?.file?.url,
    [UISettingsFormFields.MOBILE_HEADER_LOGO]: mobileHeaderLogo?.at(0)?.file?.url,
    [UISettingsFormFields.COMPANY_LOGO]: companyLogo?.at(0)?.file?.url,
    [UISettingsFormFields.FAVICON48]: favicon48?.at(0)?.file?.url,
    [UISettingsFormFields.FAVICON96]: favicon96?.at(0)?.file?.url,
    [UISettingsFormFields.FAVICON144]: favicon144?.at(0)?.file?.url,
  };
};

export const mapGeneralInitialFromValues = (settings: ISetting) => {
  return {
    [GeneralSettingsFormFields.ADMIN_EMAILS]: settings?.adminEmails,
    [GeneralSettingsFormFields.CHANNEL]: settings?.channel?.slug,
    [GeneralSettingsFormFields.COPYRIGHT]: settings?.copyright,
    [GeneralSettingsFormFields.GOOGLE_TAG_MANAGER]: settings?.googleTagManagement,
    [GeneralSettingsFormFields.PHONES_CONTACT_US]: settings?.phones?.contactUsPage,
    [GeneralSettingsFormFields.PHONES_FOOTER]: settings?.phones?.footer,
    [GeneralSettingsFormFields.SMTP_AUTH_PASS]: settings?.smtp?.auth?.pass,
    [GeneralSettingsFormFields.SMTP_AUTH_USER]: settings?.smtp?.auth?.user,
    [GeneralSettingsFormFields.SMTP_HOST]: settings?.smtp?.host,
    [GeneralSettingsFormFields.SMTP_PORT]: settings?.smtp?.port,
    [GeneralSettingsFormFields.POPULAR_PRODUCTS]: settings?.popularProductIds,
    [GeneralSettingsFormFields.CONTACT_INFO_EMAIL]: settings?.contactInfo?.email,
    [GeneralSettingsFormFields.CONTACT_INFO_WORKING_HOURS]: settings?.contactInfo?.workingHours,
    [GeneralSettingsFormFields.CONTACT_INFO_ADDRESS]: settings?.contactInfo?.address,
    [GeneralSettingsFormFields.POPULAR_CATEGORIES]: settings?.popularCategoriesIds,
    [GeneralSettingsFormFields.TOP_PICKS_OF_THE_MONTH]: settings?.topPicksOfTheMonthProductIds,
    [GeneralSettingsFormFields.HOME_PAGE_BLOG_POST]: settings?.homePageBlogPostSlug,
    [GeneralSettingsFormFields.NEW_MACHINES_CATEGORY]: settings?.newMachinesCategory,
    [GeneralSettingsFormFields.MONTHLY_SPECIAL_CATEGORY]: settings?.monthlySpecialCategory,
  };
};

export const mapBulkGeneralInitialFromValues = (settings: ISetting) => {
  return {
    ...mapGeneralInitialFromValues(settings),
    [GeneralSettingsFormFields.CHANNEL]: settings?.channel?.id ? [settings?.channel?.id] : [],
    [GeneralSettingsBulkFormFields.ADMIN_EMAILS]: true,
    [GeneralSettingsBulkFormFields.CHANNEL]: true,
    [GeneralSettingsBulkFormFields.COPYRIGHT]: true,
    [GeneralSettingsBulkFormFields.GOOGLE_TAG_MANAGER]: true,
    [GeneralSettingsBulkFormFields.PHONES_CONTACT_US]: true,
    [GeneralSettingsBulkFormFields.PHONES_FOOTER]: true,
    [GeneralSettingsBulkFormFields.SMTP_AUTH_PASS]: true,
    [GeneralSettingsBulkFormFields.SMTP_AUTH_USER]: true,
    [GeneralSettingsBulkFormFields.SMTP_HOST]: true,
    [GeneralSettingsBulkFormFields.SMTP_PORT]: true,
    [GeneralSettingsBulkFormFields.POPULAR_PRODUCTS]: true,
    [GeneralSettingsBulkFormFields.CONTACT_INFO_EMAIL]: true,
    [GeneralSettingsBulkFormFields.CONTACT_INFO_WORKING_HOURS]: true,
    [GeneralSettingsBulkFormFields.CONTACT_INFO_ADDRESS]: true,
    [GeneralSettingsBulkFormFields.POPULAR_CATEGORIES]: true,
    [GeneralSettingsBulkFormFields.TOP_PICKS_OF_THE_MONTH]: true,
    [GeneralSettingsBulkFormFields.HOME_PAGE_BLOG_POST]: true,
  };
};
