import { useUploadFile } from '@/modules/Upload/hooks/useFileUpload';
import React, { useCallback, useImperativeHandle, useMemo, useRef } from 'react';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import { TextEditorContainer } from './TextEditor.styles';

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'code',
  'image',
];

interface OnChangeHandler {
  (html: string, plainText: string): void;
}

type Props = {
  value?: string;
  placeholder?: string;
  onChange?: OnChangeHandler;
  enableImageUpload?: boolean;
};

export const TextEditor = React.forwardRef<ReactQuill | null, Props>(
  ({ value, onChange, placeholder, enableImageUpload = false }, ref) => {
    const prevValue = useRef<string>('');
    const { uploadFile } = useUploadFile();
    const quillRef = useRef<ReactQuill | null>(null);

    const handleChange = (_: string, delta: any, __: string, editor: any) => {
      const plainText = editor.getText();
      const html = editor.getHTML();
      if (html !== prevValue.current && onChange) {
        prevValue.current = html;
        onChange(html, plainText);
      }
    };

    useImperativeHandle(ref, () => quillRef.current!);

    const handleImageUpload = useCallback(
      async (file: File) => {
        try {
          const response = await uploadFile({ file });
          const imageUrl = response.url;

          const editor = quillRef?.current?.getEditor();
          const range = editor?.getSelection();
          if (range) {
            editor?.insertEmbed(range.index, 'image', imageUrl);
          }
        } catch (error) {
          console.error('Image upload failed:', error);
        }
      },
      [uploadFile],
    );

    const handleImageInsert = useCallback(() => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();

      input.onchange = () => {
        if (input.files && input.files[0]) {
          handleImageUpload(input.files[0]);
        }
      };
    }, [handleImageUpload]);

    const modules = useMemo(() => {
      const baseToolbar = [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link', 'code'],
        ['clean'],
      ];

      if (enableImageUpload) {
        baseToolbar.push(['image']);
      }

      const handlers = enableImageUpload
        ? {
            image: handleImageInsert,
          }
        : {};

      return {
        toolbar: {
          container: baseToolbar,
          handlers,
        },
      };
    }, [enableImageUpload, handleImageInsert]);

    return (
      <TextEditorContainer>
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={value || ''}
          modules={modules}
          formats={formats}
          onChange={handleChange}
          placeholder={placeholder}
        />
      </TextEditorContainer>
    );
  },
);

TextEditor.displayName = 'CustomTextEditor';
