import { IApiError } from '@/models/apiError.model';
import { IUploadedFile } from '@/models/upload.model';

export const SLICE_NAME = 'uloadFile';

export interface UploadFileState {
  uploadedFile: IUploadedFile | null;
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: UploadFileState = {
  uploadedFile: null,
  isLoading: false,
  error: null,
};
