import { ChannelStatus, ChannelType } from '@/models/channel.model';

export enum ModalViews {
  CREATE = 'create',
  EDIT = 'edit',
  DEACTIVATE = 'deactivate',
  ACTIVATE = 'activate',
}

export enum CreateChannelFormFields {
  NAME = 'name',
  SITE_URL = 'siteUrl',
  COPY_CHANNEL_ID = 'copyChannelId',
  SLUG = 'slug',
  TYPE = 'type',
  HIDE_SERIAL_NUMBER = 'hideSerialNumber',
}

export enum UpdateChannelFormFields {
  SITE_URL = 'siteUrl',
  STATUS = 'status',
  HIDE_SERIAL_NUMBER = 'hideSerialNumber',
}

export type TCreateChannelFormValues = {
  [CreateChannelFormFields.NAME]: string;
  [CreateChannelFormFields.SITE_URL]: string;
  [CreateChannelFormFields.COPY_CHANNEL_ID]: number;
  [CreateChannelFormFields.SLUG]: string;
  [CreateChannelFormFields.TYPE]: ChannelType;
  [CreateChannelFormFields.HIDE_SERIAL_NUMBER]: boolean;
};

export type TUpdateChannelFormValues = {
  [UpdateChannelFormFields.SITE_URL]: string;
  [UpdateChannelFormFields.STATUS]: ChannelStatus;
  [UpdateChannelFormFields.HIDE_SERIAL_NUMBER]: boolean;
};

export const ChannelTypeFilterValues = [
  { label: 'All', value: '' },
  { label: 'Products', value: ChannelType.PRODUCTS },
  { label: 'Parts', value: ChannelType.PARTS },
];
