export enum ChannelEndpoints {
  ROOT = '/channels',
}

export const staticChannelEndpoints = {
  GET_CHANNELS: ChannelEndpoints.ROOT,
  CREATE_CHANNEL: ChannelEndpoints.ROOT,
};

export const getChannelEndpoints = ({ id }: { id: number }) => ({
  UPDATE_CHANNEL: `${ChannelEndpoints.ROOT}/${id}`,
});

export interface IChannel {
  id: number;
  slug: string;
  name: string;
  status: ChannelStatus;
  type: ChannelType;
  siteUrl: string;
  hideSerialNumber: boolean;
  createdAt: Date;
}

export enum ChannelStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ChannelType {
  PRODUCTS = 'PRODUCTS',
  PARTS = 'PARTS',
}
// ============== DTO ==============

export type IUpdateChannelDTO = Partial<Pick<IChannel, 'name' | 'status' | 'siteUrl' | 'hideSerialNumber'>> & {
  id: number;
};

export interface ICreateChannelDTO extends Pick<IChannel, 'name' | 'siteUrl' | 'slug' | 'type' | 'hideSerialNumber'> {
  copyChannelId: number;
}

// ============== Response ==============

export type ICreateChannelResponse = IChannel;

export type IUpdateChannelResponse = IChannel;

export type IGetChannelsResponse = IChannel[];
