// Globals
import { Color } from 'antd/es/color-picker';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Store
import { useAppDispatch, useAppState } from '@/store';
import { getSettings, updateUISettings } from '../../feature';

// Modules
import { selectChannel } from '@/modules/Channels';
import { Header } from '@/modules/Layout';
import { ExampleButton, ExampleFooter, PaginationInfo } from '@/modules/UIExamples';

// Components
import { Form, useForm, useWatchForm } from '@/components';
import { CheckIcon } from '@/icons';
import { Button, ColorPicker, Spinner } from '@/ui';
import { ImageUpload } from '../../components';
import {
  StyledBlock,
  StyledColorFormItem,
  StyledColors,
  StyledContainer,
  StyledFormItem,
  StyledTitle,
} from './UISettingsContainerStyles';

// Helpers
import { useBoolean } from '@/hooks';
import { COLORS } from '@/theme';
import { UpdateUISettingsDTO } from '../../dtos';
import { SettingsForms, TUISettingsFormValues, UISettingsFormFields, mapUIInitialFormValues } from '../../helpers';
import { useGetSettings, useUISettingsFormRules } from '../../hooks';

const UISettingsContainer = () => {
  const { t } = useTranslation(['common']);
  const { value: isEditing, setFalse: stopEditing, setTrue: startEditing } = useBoolean();
  const dispatch = useAppDispatch();
  const currentChannel = useAppState(selectChannel);
  const { settings, isLoading } = useGetSettings(currentChannel?.slug);
  const rules = useUISettingsFormRules();
  const [form] = useForm();

  const primaryColor = useWatchForm<Color>(UISettingsFormFields.PRIMARY_COLOR, form);
  const primaryHoverColor = useWatchForm<Color>(UISettingsFormFields.PRIMARY_HOVER_COLOR, form);
  const disabledColor = useWatchForm<Color>(UISettingsFormFields.DISABLED_COLOR, form);
  const colorOnPrimary = useWatchForm<Color>(UISettingsFormFields.COLOR_ON_PRIMARY, form);
  const secondaryColor = useWatchForm<Color>(UISettingsFormFields.SECONDARY_COLOR, form);
  const colorOnSecondary = useWatchForm<Color>(UISettingsFormFields.COLOR_ON_SECONDARY, form);

  useEffect(() => {
    if (!settings) return;
    // TODO: fix initial values
    form.resetFields();
    form.setFieldsValue(mapUIInitialFormValues(settings));
  }, [form, settings]);

  const handleFinish = async (values: Partial<TUISettingsFormValues>) => {
    if (!settings || !currentChannel) return;
    const dto = new UpdateUISettingsDTO({ ...values, channelId: currentChannel.id });
    try {
      await dispatch(updateUISettings(dto)).unwrap();
      const response = await dispatch(getSettings({ channel: currentChannel.slug })).unwrap();
      form.setFieldsValue(mapUIInitialFormValues(response));
      stopEditing();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Header title={t('menu_ui')} suptitle={t('menu_website_management')}>
        {isEditing && (
          <Button
            type="primary"
            size="small"
            loading={isLoading}
            form={SettingsForms.UI}
            htmlType="submit"
            icon={<CheckIcon />}
          >
            {t('btn_save')}
          </Button>
        )}
      </Header>
      <Spinner spinning={isLoading}>
        {settings && (
          <Form
            form={form}
            onFinish={handleFinish}
            initialValues={mapUIInitialFormValues(settings)}
            name={SettingsForms.UI}
            onValuesChange={startEditing}
          >
            <StyledContainer>
              <StyledTitle>{t('title_ui_logo')}</StyledTitle>
              <StyledBlock>
                <StyledFormItem
                  name={UISettingsFormFields.HEADER_LOGO}
                  label={t('label_logo_header')}
                  rules={rules[UISettingsFormFields.HEADER_LOGO]}
                >
                  <ImageUpload accept="image/*" recommendedSize={{ width: 352, height: 96 }} />
                </StyledFormItem>
                <StyledFormItem
                  name={UISettingsFormFields.FOOTER_LOGO}
                  label={t('label_logo_footer')}
                  rules={rules[UISettingsFormFields.FOOTER_LOGO]}
                  $background={COLORS.BLACK}
                >
                  <ImageUpload accept="image/*" recommendedSize={{ width: 352, height: 96 }} />
                </StyledFormItem>
                <StyledFormItem
                  name={UISettingsFormFields.MOBILE_HEADER_LOGO}
                  label={t('label_logo_mobile_header')}
                  rules={rules[UISettingsFormFields.MOBILE_HEADER_LOGO]}
                >
                  <ImageUpload accept="image/*" recommendedSize={{ width: 166, height: 48 }} />
                </StyledFormItem>
              </StyledBlock>
              <StyledBlock>
                <StyledFormItem
                  name={UISettingsFormFields.EMPTY_STATE_LOGO}
                  rules={rules[UISettingsFormFields.EMPTY_STATE_LOGO]}
                  label={t('title_ui_icon')}
                >
                  <ImageUpload accept="image/*" recommendedSize={{ width: 304, height: 304 }} />
                </StyledFormItem>
              </StyledBlock>
              <StyledBlock>
                <StyledFormItem
                  name={UISettingsFormFields.COMPANY_LOGO}
                  rules={rules[UISettingsFormFields.COMPANY_LOGO]}
                  label={t('label_company_logo')}
                >
                  <ImageUpload accept="image/*" />
                </StyledFormItem>
              </StyledBlock>
            </StyledContainer>

            <StyledContainer>
              <StyledTitle>{t('title_ui_favicon')}</StyledTitle>
              <StyledBlock>
                <StyledFormItem
                  name={UISettingsFormFields.FAVICON48}
                  label={t('label_favicon_48')}
                  rules={rules[UISettingsFormFields.FAVICON48]}
                >
                  <ImageUpload accept="image/*" />
                </StyledFormItem>
                <StyledFormItem
                  name={UISettingsFormFields.FAVICON96}
                  label={t('label_favicon_96')}
                  rules={rules[UISettingsFormFields.FAVICON96]}
                >
                  <ImageUpload accept="image/*" />
                </StyledFormItem>
                <StyledFormItem
                  name={UISettingsFormFields.FAVICON144}
                  label={t('label_favicon_144')}
                  rules={rules[UISettingsFormFields.FAVICON144]}
                >
                  <ImageUpload accept="image/*" />
                </StyledFormItem>
              </StyledBlock>
            </StyledContainer>

            <StyledColors>
              <StyledContainer>
                <StyledTitle>{t('title_ui_colors')}</StyledTitle>
              </StyledContainer>
              <StyledContainer $background={COLORS.COLOR_SEARCH}>
                <StyledBlock>
                  <StyledColorFormItem
                    label={t('label_primary_color')}
                    $description={t('label_primary_color_description')}
                    name={UISettingsFormFields.PRIMARY_COLOR}
                    rules={rules[UISettingsFormFields.PRIMARY_COLOR]}
                  >
                    <ColorPicker disabledAlpha showText />
                  </StyledColorFormItem>
                  <ExampleButton textColor={colorOnPrimary} backgroundColor={primaryColor} />
                </StyledBlock>
                <StyledBlock>
                  <StyledColorFormItem
                    label={t('label_primary_color_hover')}
                    $description={t('label_primary_color_hover_description')}
                    name={UISettingsFormFields.PRIMARY_HOVER_COLOR}
                    rules={rules[UISettingsFormFields.PRIMARY_HOVER_COLOR]}
                  >
                    <ColorPicker disabledAlpha showText />
                  </StyledColorFormItem>
                  <ExampleButton textColor={colorOnPrimary} backgroundColor={primaryHoverColor} />
                </StyledBlock>
                <StyledBlock>
                  <StyledColorFormItem
                    label={t('label_disabled_primary_color')}
                    $description={t('label_disabled_primary_color_description')}
                    name={UISettingsFormFields.DISABLED_COLOR}
                    rules={rules[UISettingsFormFields.DISABLED_COLOR]}
                  >
                    <ColorPicker disabledAlpha showText />
                  </StyledColorFormItem>
                  <ExampleButton backgroundColor={disabledColor} textColor={colorOnPrimary} disabled />
                </StyledBlock>
                <StyledBlock>
                  <StyledColorFormItem
                    label={t('label_colors_on_primary')}
                    $description={t('label_colors_on_primary_description')}
                    name={UISettingsFormFields.COLOR_ON_PRIMARY}
                    rules={rules[UISettingsFormFields.COLOR_ON_PRIMARY]}
                  >
                    <ColorPicker disabledAlpha showText />
                  </StyledColorFormItem>
                  <ExampleButton textColor={colorOnPrimary} backgroundColor={primaryColor} />
                </StyledBlock>
              </StyledContainer>
              <StyledContainer>
                <StyledBlock>
                  <StyledColorFormItem
                    label={t('label_secondary_color')}
                    $description={t('label_secondary_color_description')}
                    name={UISettingsFormFields.SECONDARY_COLOR}
                    rules={rules[UISettingsFormFields.SECONDARY_COLOR]}
                  >
                    <ColorPicker disabledAlpha showText />
                  </StyledColorFormItem>
                  <div>
                    <PaginationInfo backgroundColor={secondaryColor} textColor={colorOnSecondary} />
                  </div>
                </StyledBlock>
                <StyledBlock>
                  <StyledColorFormItem
                    label={t('label_colors_on_secondary')}
                    $description={t('label_colors_on_secondary_description')}
                    name={UISettingsFormFields.COLOR_ON_SECONDARY}
                    rules={rules[UISettingsFormFields.COLOR_ON_SECONDARY]}
                  >
                    <ColorPicker disabledAlpha showText />
                  </StyledColorFormItem>
                  {settings && (
                    <ExampleFooter settings={settings} backgroundColor={secondaryColor} textColor={colorOnSecondary} />
                  )}
                </StyledBlock>
              </StyledContainer>
            </StyledColors>
          </Form>
        )}
      </Spinner>
    </>
  );
};

export default UISettingsContainer;
