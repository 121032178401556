import { useGetRequiredRule, useLengthValidationRule, useSlugValidationRule } from '@/hooks';
import { useTranslation } from 'react-i18next';
import { BlogPostFormFields } from '../helpers/types';

const useBlogPostFormRules = () => {
  const { t } = useTranslation(['validations']);

  const requiredRule = useGetRequiredRule({ t });
  const lengthRule255 = useLengthValidationRule({ t, max: 255 });
  const lengthRule1500 = useLengthValidationRule({ t, max: 1500 });
  const lengthRule20000 = useLengthValidationRule({ t, max: 20_000 });
  const slugRule = useSlugValidationRule({ t });

  return {
    [BlogPostFormFields.SLUG]: [requiredRule, slugRule],
    [BlogPostFormFields.STATUS]: [requiredRule],
    [BlogPostFormFields.PUBLISHED_AT]: [],
    [BlogPostFormFields.TITLE]: [requiredRule, lengthRule255],
    [BlogPostFormFields.SHORT_DESCRIPTION]: [requiredRule, lengthRule1500],
    [BlogPostFormFields.CONTENT]: [requiredRule, lengthRule20000],
    [BlogPostFormFields.BLOG_POST_CHANNELS]: [requiredRule],
    [BlogPostFormFields.SIMILAR_BLOG_POSTS]: [slugRule],
  };
};

export default useBlogPostFormRules;
