import { useMemo } from 'react';
import { TFunction } from 'i18next';
import {
  channelRegex,
  emailRegex,
  nameRegex,
  numbersOnlyRegex,
  passwordRegex,
  phoneRegex,
  urlRegex,
  zipCodeRegex,
} from '@/constants/regex';
import { TFormValidatorRule } from '@/types';

const getRequiredRule = (t: TFunction) => ({
  required: true,
  message: t('validation_message_required', { ns: 'validation' }),
});

const getTextLengthValidationRule = (t: TFunction, max: number, min?: number) => {
  const messageKey = min ? 'validation_text_message_length' : 'validation_message_max_length';

  return {
    min,
    max,
    message: t(messageKey, { ns: 'validation', min, max }),
  };
};

const getArrayLengthValidationRule = (t: TFunction, max: number, min?: number) => {
  const messageKey = min ? 'validation_message_array_length' : 'validation_message_array_max_length';

  return {
    min,
    max,
    type: 'array' as const,
    message: t(messageKey, { ns: 'validation', min, max }),
  };
};

export const useGetRequiredRule = ({ t }: { t: TFunction }) => useMemo(() => getRequiredRule(t), [t]);

export const useArrayLengthValidationRule = ({ t, min, max }: { t: TFunction; max: number; min?: number }) =>
  useMemo(() => getArrayLengthValidationRule(t, max, min), [t, max, min]);

export const useLengthValidationRule = ({ t, min, max }: { t: TFunction; max: number; min?: number }) =>
  useMemo(() => getTextLengthValidationRule(t, max, min), [t, max, min]);

export const useSlugValidationRule = ({ t }: { t: TFunction }) =>
  useMemo(
    () => ({
      pattern: /^[a-z0-9]+(?:_[a-z0-9]+)*$/,
      message: t('validation_message_slug', { ns: 'validation' }),
    }),
    [t],
  );

export const useGetEmailRule = ({ t }: { t: TFunction }) =>
  useMemo(
    () => ({
      pattern: emailRegex,
      message: t('validation_message_email', { ns: 'validation' }),
    }),
    [t],
  );

export const useGetChannelRule = ({ t }: { t: TFunction }) =>
  useMemo(
    () => ({
      pattern: channelRegex,
      message: t('validation_message_channel', { ns: 'validation' }),
    }),
    [t],
  );

export const useGetPasswordLengthRule = ({ t }: { t: TFunction }) =>
  useMemo(
    () => ({
      min: 8,
      message: t('validation_password_message_length', { ns: 'validation' }),
    }),
    [t],
  );

export const useGetPasswordRule = ({ t }: { t: TFunction }) =>
  useMemo(
    () => ({
      pattern: passwordRegex,
      message: t('validation_password_message_format', { ns: 'validation' }),
    }),
    [t],
  );

export const useGetFullNameRule = ({ t }: { t: TFunction }) =>
  useMemo(
    () => ({
      pattern: nameRegex,
      message: t('validation_message_full_name', { ns: 'validation' }),
    }),
    [t],
  );

export const useGetZipCodeRule = ({ t }: { t: TFunction }) =>
  useMemo(
    () => ({
      pattern: zipCodeRegex,
      message: t('validation_message_zip_code', { ns: 'validation' }),
    }),
    [t],
  );

export const useGetPhoneRule = ({ t }: { t: TFunction }) =>
  useMemo(
    () => ({
      pattern: phoneRegex,
      message: t('validation_message_phone', { ns: 'validation' }),
    }),
    [t],
  );

export const useGetUrlRule = ({ t }: { t: TFunction }) =>
  useMemo(
    () => ({
      pattern: urlRegex,
      message: t('validation_message_url', { ns: 'validation' }),
    }),
    [t],
  );

export const useGetNumbersOnlyRule = ({ t }: { t: TFunction }) =>
  useMemo(
    () => ({
      pattern: numbersOnlyRegex,
      message: t('validation_numbers_only', { ns: 'validation' }),
    }),
    [t],
  );

export const useDependentRequiredFieldsValidator =
  <TFormFields extends string, TFormValues extends Partial<Record<TFormFields, unknown>>>(
    t: TFunction,
    fieldName: TFormFields,
    dependencyFieldName: TFormFields,
  ): TFormValidatorRule =>
  ({ getFieldsValue }) => ({
    validator() {
      const values: TFormValues = getFieldsValue();
      const { [fieldName]: fieldNameValue, [dependencyFieldName]: dependencyFieldNameValue } = values;

      if (!fieldNameValue && !dependencyFieldNameValue) {
        return Promise.resolve();
      }

      if (!fieldNameValue) {
        return Promise.reject(t('validation_message_required', { ns: 'validation' }));
      }

      return Promise.resolve();
    },
  });
