import { showApiErrors } from '@/utils';
import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { uploadFile } from './actionCreators';
import { initialState, SLICE_NAME, UploadFileState } from './models';

export const uploadFileSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadFile.fulfilled, (state: UploadFileState, action) => {
        state.uploadedFile = action.payload;
        state.isLoading = false;
      })
      .addMatcher(isPending(uploadFile), (state: UploadFileState) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejected(uploadFile), (state: UploadFileState, action) => {
        const { error } = action;
        state.isLoading = false;
        state.error = error;
        showApiErrors(error);
      });
  },
});
