import { IUploadFileDto } from '@/models/upload.model';
import { useAppDispatch, useAppState } from '@/store';
import { useCallback } from 'react';
import { uploadFile } from '../feature/actionCreators';
import { selectUploadedFile, selectFileUploadIsLoading, selectFileUploadError } from '../feature/selectors';

export const useUploadFile = () => {
  const dispatch = useAppDispatch();

  const uploadedFile = useAppState(selectUploadedFile);
  const isLoading = useAppState(selectFileUploadIsLoading);
  const error = useAppState(selectFileUploadError);

  const handleFileUpload = useCallback(
    async (fileData: IUploadFileDto) => {
      try {
        const response = await dispatch(uploadFile(fileData)).unwrap();
        return response; // Successfully uploaded
      } catch (err) {
        // Handle error if needed
        console.error('File upload failed:', err);
        throw err;
      }
    },
    [dispatch],
  );

  return {
    uploadedFile,
    isLoading,
    error,
    uploadFile: handleFileUpload,
  };
};
