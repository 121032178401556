import { showApiErrors } from '@/utils';
import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { createBlogPost, deleteBlogPost, getBlogPosts, updateBlogPost } from './actionCreators';
import { BlogPostsState, initialState, SLICE_NAME } from './models';

export const blogPostsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBlogPosts.fulfilled, (state: BlogPostsState, action) => {
        const { payload, meta } = action;
        state.blogPosts = payload?.blogPosts ?? [];
        state.pagination = {
          page: meta.arg.page,
          pageSize: meta.arg.pageSize,
          total: payload?.total,
        };
        state.isLoading = false;
      })
      .addCase(createBlogPost.fulfilled, (state: BlogPostsState, action) => {
        const newBlogPost = action.payload;

        if (state.pagination && state.pagination.page !== 1) {
          state.isLoading = false;
          return;
        }

        state.blogPosts = [newBlogPost, ...(state.blogPosts ?? [])];
        if (state.pagination) {
          state.pagination.total += 1;
          if (state.blogPosts.length > 10) {
            state.blogPosts.pop();
          }
        }

        state.isLoading = false;
      })
      .addCase(updateBlogPost.fulfilled, (state: BlogPostsState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.blogPosts = (state.blogPosts ?? []).map((blogPost) => {
          if (blogPost.slug === payload.slug) return payload;
          return blogPost;
        });
      })
      .addCase(deleteBlogPost.fulfilled, (state: BlogPostsState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.blogPosts = (state.blogPosts ?? []).filter((blogPost) => blogPost.slug !== payload);
        state.pagination!.total -= 1;
      })
      .addMatcher(isPending(getBlogPosts, createBlogPost, updateBlogPost, deleteBlogPost), (state: BlogPostsState) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(
        isRejected(getBlogPosts, createBlogPost, updateBlogPost, deleteBlogPost),
        (state: BlogPostsState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;
          showApiErrors(error);
        },
      );
  },
});
