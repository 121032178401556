// Globals
import { SelectProps } from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { forwardRef, MouseEvent, ReactNode, useCallback } from 'react';
import { BaseSelectRef } from 'rc-select';

// Components
import { CloseIcon } from '@/icons';
import EmptyDataThumbnail from '../EmptyDataThumbnail/EmptyDataThumbnail';
import PureIconButton from '../PureIconButton/PureIconButton';
import {
  SelectDropdownGlobalStyles,
  StyledDownIcon,
  StyledDownWrapper,
  StyledPrefix,
  StyledSelect,
  StyledTag,
  StyledWrapper,
} from './SelectStyles';

export interface ISelectOption<V> {
  label: JSX.Element | string;
  value: V;
}

export type TSelectOptions<V> = Array<ISelectOption<V>>;

export interface ISelectProps<Value, OptionValue> extends SelectProps<Value, ISelectOption<OptionValue>> {
  isMultiselect?: boolean;
  noResultMessage?: string;
  options?: TSelectOptions<OptionValue>;
  prefix?: ReactNode;
  onChange?: (value: any) => any;
}

const Select = forwardRef<BaseSelectRef, ISelectProps<number | number[] | string | string[], number | string>>(
  (
    {
      isMultiselect,
      options,
      noResultMessage,
      value,
      suffixIcon,
      prefix,
      id,
      loading,
      notFoundContent,
      ...props
    }: ISelectProps<number | number[] | string | string[], number | string>,
    ref,
  ): JSX.Element => {
    const renderTag = useCallback(({ label, onClose }: CustomTagProps) => {
      const handleClose = (evt: MouseEvent) => {
        evt.preventDefault();
        evt.stopPropagation();
        onClose();
      };

      const onPreventMouseDown = (event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
      };

      return (
        <StyledTag onMouseDown={onPreventMouseDown}>
          <span>{label}</span>
          <PureIconButton onClick={handleClose}>
            <CloseIcon />
          </PureIconButton>
        </StyledTag>
      );
    }, []);

    const renderDropdown = useCallback((menu: ReactNode) => <StyledDownWrapper>{menu}</StyledDownWrapper>, []);

    const suffixIconComponent = suffixIcon === null ? null : (suffixIcon ?? <StyledDownIcon />);

    const selectComponent = (
      <StyledSelect<number | number[] | string | string[], ISelectOption<number | string>>
        {...props}
        ref={ref}
        dropdownRender={renderDropdown}
        notFoundContent={
          notFoundContent === null ? null : (notFoundContent ?? <EmptyDataThumbnail message={noResultMessage} />)
        }
        optionFilterProp="label"
        options={options}
        suffixIcon={suffixIconComponent}
        tagRender={renderTag}
        value={value}
        loading={loading}
        {...(isMultiselect ? { mode: 'multiple' } : {})}
        // Hack for disabling browser autocomplete
        // eslint-disable-next-line
        // @ts-ignore
        autoComplete="dontshow"
      />
    );

    return (
      <StyledWrapper id={id}>
        {!!prefix && <StyledPrefix>{prefix}</StyledPrefix>}

        {selectComponent}
        <SelectDropdownGlobalStyles />
      </StyledWrapper>
    );
  },
);

Select.displayName = 'CustomSelect';

export default Select;
