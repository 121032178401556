import { BlogPostStatus, ICreateBlogPostDataDTO } from '@/models/blogPost.model';
import { Dayjs } from 'dayjs';
import { BlogPostFormFields, TCreateBlogPostFormValues } from '../helpers/types';

class CreateBlogPostDTO implements ICreateBlogPostDataDTO {
  slug: string;

  title: string;

  shortDescription: string;

  content: string;

  status?: BlogPostStatus;

  publishedAt?: string;

  blogPostChannels?: number[];

  blogPostFiles?: string[];

  banner?: File;

  similarBlogPostIds?: string[];

  constructor(values: TCreateBlogPostFormValues & { blogPostFiles: string[] }) {
    this.slug = values[BlogPostFormFields.SLUG]; // Assuming the field exists in the form values
    this.title = values[BlogPostFormFields.TITLE]; // Assuming the field exists in the form values
    this.shortDescription = values[BlogPostFormFields.SHORT_DESCRIPTION]; // Assuming the field exists
    this.content = values[BlogPostFormFields.CONTENT]; // Assuming the field exists
    this.similarBlogPostIds = this.getSimilarBlogIds(values[BlogPostFormFields.SIMILAR_BLOG_POSTS]);

    // Optional fields, can be set based on form values or defaults
    this.status = values[BlogPostFormFields.STATUS] || BlogPostStatus.DRAFT;
    this.publishedAt = values[BlogPostFormFields.PUBLISHED_AT]
      ? (values[BlogPostFormFields.PUBLISHED_AT] as Dayjs).toISOString()
      : undefined;

    if (values[BlogPostFormFields.BANNER] instanceof File) {
      this.banner = values[BlogPostFormFields.BANNER];
    }

    this.blogPostChannels = values[BlogPostFormFields.BLOG_POST_CHANNELS] || [];
    this.blogPostFiles = values.blogPostFiles;
  }

  getSimilarBlogIds(formValue?: string[] | { label: string; value: string }[]): string[] {
    if (!formValue || !formValue.length) {
      return [];
    }
    if (typeof formValue[0] === 'string') {
      return formValue as string[];
    }
    return (formValue as { label: string; value: string }[]).map(
      (item: { label: string; value: string }) => item.value,
    );
  }
}

export default CreateBlogPostDTO;
