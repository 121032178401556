import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BlogAPI } from '@/api';
import { IBlogPost } from '@/models/blogPost.model';

const searchBlogPosts = async (value: string) => {
  try {
    const response = await BlogAPI.getBlogPosts({
      page: 1,
      pageSize: 20,
      search: value.trim(),
    });
    return response.data;
  } catch (error) {
    console.error('Error searching blog posts:', error);
    return null;
  }
};

export const useSearchArticles = () => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<IBlogPost[]>([]);
  const abortControllerRef = useRef<AbortController | null>(null);

  const debouncedSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        if (!value.trim()) {
          setResult([]);
          setLoading(false);
          return;
        }

        setLoading(true);
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }

        abortControllerRef.current = new AbortController();

        try {
          const response = await searchBlogPosts(value);
          setResult(response?.blogPosts ?? []);
        } finally {
          setLoading(false);
        }
      }, 500),
    [],
  );

  const handleSearch = useCallback(
    (value: string) => {
      debouncedSearch(value);
    },
    [debouncedSearch],
  );

  const options = useMemo(
    () =>
      result.map((post) => ({
        value: post.id,
        label: post.title,
      })),
    [result],
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [debouncedSearch]);

  return useMemo(
    () => ({
      searchArticles: handleSearch,
      result,
      loading,
      options,
    }),
    [handleSearch, result, loading, options],
  );
};
