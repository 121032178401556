import { BlogPostStatus } from '@/models/blogPost.model';
import { Dayjs } from 'dayjs';

export enum BlogPostFormFields {
  SLUG = 'slug',
  TITLE = 'title',
  CONTENT = 'content',
  SHORT_DESCRIPTION = 'shortDescription',
  STATUS = 'status',
  PUBLISHED_AT = 'publishedAt',
  BLOG_POST_CHANNELS = 'blogPostChannels',
  BANNER = 'banner',
  SIMILAR_BLOG_POSTS = 'similarBlogPostIds',
}

export type TCreateBlogPostFormValues = {
  [BlogPostFormFields.SLUG]: string;
  [BlogPostFormFields.CONTENT]: string;
  [BlogPostFormFields.TITLE]: string;
  [BlogPostFormFields.SHORT_DESCRIPTION]: string;
  [BlogPostFormFields.STATUS]: BlogPostStatus;
  [BlogPostFormFields.PUBLISHED_AT]: Dayjs;
  [BlogPostFormFields.BLOG_POST_CHANNELS]: number[];
  [BlogPostFormFields.BANNER]: string | File;
  [BlogPostFormFields.SIMILAR_BLOG_POSTS]?: string[];
};

export type TUpdateBlogPostFormValues = {
  [BlogPostFormFields.SLUG]: string;
  [BlogPostFormFields.CONTENT]: string;
  [BlogPostFormFields.TITLE]: string;
  [BlogPostFormFields.SHORT_DESCRIPTION]: string;
  [BlogPostFormFields.STATUS]: BlogPostStatus;
  [BlogPostFormFields.PUBLISHED_AT]: Dayjs;
  [BlogPostFormFields.BLOG_POST_CHANNELS]: number[];
  [BlogPostFormFields.BANNER]: string | File;
  [BlogPostFormFields.SIMILAR_BLOG_POSTS]?: string[] | { label: string; value: string }[];
};

export enum BlogPostFormType {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}
