// Global imports
import { Form } from 'antd';
import { FC } from 'react';

// Store
import { useAppState } from '@/store';
import { selectCurrentPage } from '@/modules/Pages/feature';

// Hooks
import { usePageValidations } from '../../../hooks/usePageValidations';

// Components
import { ImageUpload } from '@/modules/Settings/components';
import { Input, TextArea } from '@/ui';
import { useTranslation } from 'react-i18next';
import { VariableType } from 'src/shared/ui/VariablesDetector/VariableDetector.typedefs';
import { WideFormItem } from '../../../UpdatePage.styles';

// Helpers
import { imagesSizesConfig } from '../../../../../utils/imagesSizesConfig';

// Types
interface UpdateContentAdvantageBlockProps {
  fieldName: string | number;
  registerInputRef: (fieldName: string, ref: any, type: VariableType) => void;
  handleInputChange: (fieldName: string, value: string) => void;
}

export const UpdateContentAdvantageBlock: FC<UpdateContentAdvantageBlockProps> = ({
  fieldName,
  registerInputRef,
  handleInputChange,
}) => {
  const { getRulesFor, getAcceptTypesFor } = usePageValidations();
  const { t } = useTranslation(['common']);
  const currentPage = useAppState(selectCurrentPage);

  if (!currentPage) return null;

  return (
    <>
      <Form.Item name={[fieldName, 'order']} noStyle>
        <Input type="hidden" />
      </Form.Item>
      <WideFormItem
        label={t('update_page_form_content_description')}
        name={[fieldName, 'description']}
        rules={getRulesFor(['content', 'banner', 'blocks', 'description'])}
      >
        <TextArea
          ref={(ref) =>
            registerInputRef(
              `${t('update_page_form_content_description')} [${+fieldName + 1}]`,
              ref,
              VariableType.TEXT_AREA,
            )
          }
          onChange={(e) =>
            handleInputChange(`${t('update_page_form_content_description')} [${+fieldName + 1}]`, e.target.value)
          }
          placeholder={t('update_page_form_content_description_placeholder')}
          rows={2}
        />
      </WideFormItem>

      <WideFormItem
        label={t('update_page_form_content_logo')}
        name={[fieldName, 'image']}
        rules={getRulesFor(['content', 'banner', 'blocks', 'image'])}
      >
        <ImageUpload
          accept={getAcceptTypesFor(['content', 'banner', 'blocks', 'image'])}
          recommendedSize={imagesSizesConfig?.[currentPage.type]?.blocks}
        />
      </WideFormItem>
    </>
  );
};
