import { IApiError } from '@/models/apiError.model';
import {
  ICreateBlogPostDataDTO,
  ICreateBlogPostResponse,
  IDeleteBlogPostDTO,
  IDeleteBlogPostResponse,
  IGetBlogPostsOptionsDTO,
  IUpdateBlogPostDataDTO,
  IUpdateBlogPostResponse,
  TGetBlogPostsResponse,
} from '@/models/blogPost.model';
import { formatApiError } from '@/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import BlogAPI from 'src/api/BlogAPI';
import { SLICE_NAME } from './models';

export const getBlogPosts = createAsyncThunk<
  TGetBlogPostsResponse,
  IGetBlogPostsOptionsDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/getBlogPosts`,
  async (params) => {
    const response = await BlogAPI.getBlogPosts(params);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const updateBlogPost = createAsyncThunk<
  IUpdateBlogPostResponse,
  IUpdateBlogPostDataDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/updateBlogPost`,
  async (data) => {
    const response = await BlogAPI.updateBlogPost(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const deleteBlogPost = createAsyncThunk<
  IDeleteBlogPostResponse,
  IDeleteBlogPostDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/deleteBlogPost`,
  async (data) => {
    await BlogAPI.deleteBlogPost(data);
    return data.slug;
  },
  { serializeError: formatApiError },
);

export const createBlogPost = createAsyncThunk<
  ICreateBlogPostResponse,
  ICreateBlogPostDataDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/createBlogPost`,
  async (data) => {
    const response = await BlogAPI.createBlogPost(data);
    return response.data;
  },
  { serializeError: formatApiError },
);
