// Global
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Components
import { ImageUpload } from '@/modules/Settings/components';
import { Input, TextEditor } from '@/ui';
import { FormContentBlocksList } from '../formLists/FormContentBlocksList';

// Hooks
import { usePageValidations } from '../../hooks/usePageValidations';

// Store
import { selectCurrentPage } from '@/modules/Pages/feature';
import { WideFormItem } from '../../UpdatePage.styles';

// Types
import { VariableDetector } from 'src/shared/ui/VariablesDetector';
import { useVariableDetector } from 'src/shared/ui/VariablesDetector/hooks/useVariableDetector';
import { VariableType } from 'src/shared/ui/VariablesDetector/VariableDetector.typedefs';
import { UpdatePageInput } from '../../UpdatePage.typedefs';
import { imagesSizesConfig } from '../../../../utils/imagesSizesConfig';

export const UpdatePageContentBannerPart = () => {
  const { t } = useTranslation(['common']);
  const currentPage = useSelector(selectCurrentPage);
  const { getRulesFor, getAcceptTypesFor } = usePageValidations();
  const { variables, selectVariableInInput, registerInputRef, handleInputChange } = useVariableDetector();

  const bannerProperties = currentPage?.schema?.properties?.content?.properties?.banner?.properties;

  if (!bannerProperties && !currentPage) {
    return null;
  }

  const renderInputs = (contentSchema: any) => {
    return Object.keys(contentSchema).map((fieldName) => {
      switch (fieldName) {
        case UpdatePageInput.ID: {
          return (
            <Form.Item key={fieldName} name={['content', 'banner', 'id']} noStyle>
              <Input type="hidden" />
            </Form.Item>
          );
        }
        case UpdatePageInput.TITLE:
          return (
            <WideFormItem
              label={t('update_page_form_content_title')}
              name={['content', 'banner', 'title']}
              rules={getRulesFor(['content', 'banner', 'title'])}
            >
              <Input
                ref={(ref) => registerInputRef(t('update_page_form_content_title'), ref, VariableType.INPUT)}
                onChange={(e) => handleInputChange(t('update_page_form_content_title'), e.target.value)}
                placeholder={t('update_page_form_content_title_placeholder')}
              />
            </WideFormItem>
          );
        case UpdatePageInput.DESCRIPTION:
          return (
            <WideFormItem
              label={t('update_page_form_content_text')}
              name={['content', 'banner', 'description']}
              rules={getRulesFor(['content', 'banner', 'description'])}
            >
              <TextEditor
                ref={(ref) => registerInputRef(t('update_page_form_content_text'), ref, VariableType.TEXT_EDITOR)}
                onChange={(_, plainText) => handleInputChange(t('update_page_form_content_text'), plainText)}
                placeholder={t('update_page_form_content_text_placeholder')}
              />
            </WideFormItem>
          );
        case UpdatePageInput.IMAGE:
          return (
            <WideFormItem
              label={t('update_page_form_content_image')}
              name={['content', 'banner', 'image']}
              rules={getRulesFor(['content', 'banner', 'image'])}
            >
              <ImageUpload
                accept={getAcceptTypesFor(['content', 'banner', 'image'])}
                recommendedSize={imagesSizesConfig?.[currentPage.type]?.banner}
              />
            </WideFormItem>
          );
        case UpdatePageInput.IMAGE_NAME:
          return (
            <WideFormItem
              label={t('update_page_form_content_banner_image_name')}
              name={['content', 'banner', 'imageName']}
              rules={getRulesFor(['content', 'banner', 'imageName'])}
            >
              <Input
                ref={(ref) =>
                  registerInputRef(t('update_page_form_content_banner_image_name'), ref, VariableType.INPUT)
                }
                onChange={(e) => handleInputChange(t('update_page_form_content_banner_image_name'), e.target.value)}
                placeholder={t('update_page_form_content_banner_image_name_placeholder')}
              />
            </WideFormItem>
          );
        case UpdatePageInput.BLOCKS: {
          return (
            <FormContentBlocksList
              key={fieldName}
              fieldName={['content', 'banner', 'blocks']}
              blockType={contentSchema?.blocks?.contentType}
              handleInputChange={handleInputChange}
              registerInputRef={registerInputRef}
            />
          );
        }
        default:
          return null;
      }
    });
  };

  return (
    <>
      <VariableDetector variables={variables} onSelectVariable={selectVariableInInput} />
      {renderInputs(bannerProperties)}
    </>
  );
};
