import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';
import { SLICE_NAME, UploadFileState } from './models';

const uploadFileSelector = (state: RootState): UploadFileState => state[SLICE_NAME];

export const selectUploadedFile = createSelector(uploadFileSelector, (state: UploadFileState) => state.uploadedFile);

export const selectFileUploadIsLoading = createSelector(
  uploadFileSelector,
  (state: UploadFileState) => state.isLoading,
);

export const selectFileUploadError = createSelector(uploadFileSelector, (state: UploadFileState) => state.error);
