import { IApiError } from '@/models/apiError.model';
import { IUploadFileDto, IUploadFileResponse } from '@/models/upload.model';
import { formatApiError } from '@/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import UploadAPI from 'src/api/UploadAPI';
import { SLICE_NAME } from './models';

export const uploadFile = createAsyncThunk<IUploadFileResponse, IUploadFileDto, { serializedErrorType: IApiError }>(
  `${SLICE_NAME}/uploadFile`,
  async (data) => {
    const response = await UploadAPI.uploadFile(data);
    return response.data;
  },
  { serializeError: formatApiError },
);
