import { styled } from 'styled-components';

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-right: 2rem;

  span {
    font-weight: normal;
  }
`;
