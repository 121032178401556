import { PageType } from '@/modules/Pages/typedefs';

export enum ImagesTypes {
  BANNER = 'banner',
  GALLERY = 'gallery',
  CONTENT = 'content',
  BLOCKS = 'blocks',
}

type ImagesSizesConfig = Partial<Record<PageType, Partial<Record<ImagesTypes, { width: number; height: number }>>>>;

export const imagesSizesConfig: ImagesSizesConfig = {
  [PageType.ABOUT_US]: {
    [ImagesTypes.BANNER]: {
      width: 1920,
      height: 915,
    },
    [ImagesTypes.GALLERY]: {
      width: 912,
      height: 528,
    },
  },
  [PageType.AFFILIATES]: {
    [ImagesTypes.CONTENT]: {
      width: 404,
      height: 96,
    },
  },
  [PageType.CAREERS]: {
    [ImagesTypes.BANNER]: {
      width: 670,
      height: 400,
    },
  },
  [PageType.HOME]: {
    [ImagesTypes.BANNER]: {
      width: 1920,
      height: 544,
    },
  },
  [PageType.PRICE_PROTECTION]: {
    [ImagesTypes.BANNER]: {
      width: 576,
      height: 304,
    },
    [ImagesTypes.BLOCKS]: {
      width: 24,
      height: 24,
    },
  },
  [PageType.PAGE_404]: {
    [ImagesTypes.BANNER]: {
      width: 400,
      height: 352,
    },
  },
  [PageType.INVESTOR_INFO]: {
    [ImagesTypes.BANNER]: {
      width: 670,
      height: 400,
    },
  },
};
