export enum UploadEndpoint {
  ROOT = '/upload',
}

export const staticUploadEndpoints = {
  UPLOAD_FILE: UploadEndpoint.ROOT,
};
// ============== Model ==============

export interface IUploadedFile {
  url: string;
}

// ============== DTO ==============

export interface IUploadFileDto {
  file: File;
}

// ============== Response ==============

export type IUploadFileResponse = IUploadedFile;
