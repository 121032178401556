import { Button } from '@/ui';
import { fileDownload } from '@/utils';
import { UploadProps } from 'antd';
import { memo, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledBlock, StyledButtons, StyledFigure, StyledImageUpload, StyledInfo } from './VideoUploadStyles';
import { DownloadOutlined } from '@ant-design/icons';
type IImageUploadProps = UploadProps & {
  value?: string | File;
  onChange?: (file: File | Blob, imageUrl: string) => void;
  accept?: string;
  recommendedSize?: { width: number; height: number };
};
const VideoUpload = ({ onChange, value, accept = '.png .svg', recommendedSize, ...props }: IImageUploadProps) => {
  const { t } = useTranslation(['common']);
  const [video, setVideo] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!value) return;
    const data = typeof value === 'string' ? value : URL.createObjectURL(value);

    setVideo(data);
    videoRef.current?.load();

    return () => {
      setVideo(null);
      if (typeof value === 'string') return;
      URL.revokeObjectURL(data);
    };
  }, [value, onChange]);

  const handleSelectFile = useCallback(
    async (item: Blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(item);
      reader.onloadend = async () => {
        const url = reader.result as string;
        onChange?.(item, url);
      };
      return false;
    },
    [onChange],
  );

  const handlePrevent = async (e: SyntheticEvent) => {
    e.stopPropagation();
    if (typeof value !== 'string') return;
    try {
      const imageBlob = await fetch(value).then((res) => res.blob());
      const fileName = value.split('/').at(-1) ?? 'video';

      fileDownload(imageBlob, fileName);
    } catch {}
  };

  return (
    <StyledBlock>
      {recommendedSize && (
        <StyledInfo>
          {t('recommended_size', {
            width: recommendedSize?.width,
            height: recommendedSize?.height,
          })}
        </StyledInfo>
      )}
      <StyledImageUpload beforeUpload={handleSelectFile} showUploadList={false} accept={accept} {...props}>
        {video && (
          <StyledFigure>
            <video controls width="400" height="500" ref={videoRef}>
              <source src={video} />
            </video>
          </StyledFigure>
        )}
        <StyledButtons>
          {typeof value === 'string' && <Button onClick={handlePrevent} icon={<DownloadOutlined />} />}
          <Button>{t('btn_upload_new')}</Button>
        </StyledButtons>
      </StyledImageUpload>
    </StyledBlock>
  );
};
export default memo(VideoUpload);
