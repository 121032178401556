// Globals
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Button, Input, TextEditor } from '@/ui';
import { StyledForm, StyledFormItem, StyledFormListItem } from './UpdateCategoryFormStyles';

// Models
import { ICategory } from '@/models/category.model';

// Helpers
import {
  ALLOWED_ICON_TYPES,
  ALLOWED_IMAGE_TYPES,
  ALLOWED_VIDEO_TYPES,
  getInitialFormValues,
  TUpdateCategoryFormValues,
  UpdateCategoryFieldNames,
} from '../../helpers';
import { useUpdateCategoryFormRules } from '../../hooks';
import { FormItem, FormList, FormListError } from '@/components';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

// Modules
import { ImageUpload, VideoUpload } from '@/modules/Settings';

type IUpdateCategoryFormProps = {
  onSubmit?: (values: TUpdateCategoryFormValues) => void;
  category: ICategory;
  loading?: boolean;
};

const UpdateCategoryForm = ({ category, onSubmit, loading }: IUpdateCategoryFormProps) => {
  const { t } = useTranslation();
  const rules = useUpdateCategoryFormRules();
  const initialValues = useMemo(() => getInitialFormValues(category), [category]);

  return (
    <StyledForm onFinish={onSubmit} initialValues={initialValues}>
      <div>
        <StyledFormItem
          label={t('label_category_image')}
          name={UpdateCategoryFieldNames.IMAGE}
          rules={rules[UpdateCategoryFieldNames.IMAGE]}
        >
          <ImageUpload accept={ALLOWED_IMAGE_TYPES.join(',')} recommendedSize={{ width: 1920, height: 320 }} />
        </StyledFormItem>
        <StyledFormItem
          label={t('label_category_thumbnail')}
          name={UpdateCategoryFieldNames.THUMBNAIL}
          rules={rules[UpdateCategoryFieldNames.THUMBNAIL]}
        >
          <ImageUpload accept={ALLOWED_IMAGE_TYPES.join(',')} recommendedSize={{ width: 304, height: 304 }} />
        </StyledFormItem>
        <StyledFormItem label={t('label_category_icon')} name={UpdateCategoryFieldNames.ICON}>
          <ImageUpload accept={ALLOWED_ICON_TYPES.join(',')} recommendedSize={{ width: 64, height: 64 }} />
        </StyledFormItem>
        <StyledFormItem label={t('label_category_video')} name={UpdateCategoryFieldNames.VIDEO}>
          <VideoUpload accept={ALLOWED_VIDEO_TYPES.join(',')} recommendedSize={{ width: 1920, height: 320 }} />
        </StyledFormItem>
        <StyledFormItem
          label={t('column_description')}
          name={UpdateCategoryFieldNames.DESCRIPTION}
          rules={rules[UpdateCategoryFieldNames.IMAGE]}
        >
          <TextEditor placeholder={t('description')} />
        </StyledFormItem>
        <FormList
          name={UpdateCategoryFieldNames.ADVANTAGES}
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 1) {
                  return Promise.reject(new Error('At least 1 advantage'));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name, ...field }) => (
                <Fragment key={key}>
                  <StyledFormListItem required={false} label={t('label_advantage', { index: key + 1 })}>
                    <FormItem
                      {...field}
                      name={[name, UpdateCategoryFieldNames.TITLE]}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={rules[UpdateCategoryFieldNames.IMAGE]}
                    >
                      <Input placeholder={t('label_advantage_title')} style={{ width: '60%' }} />
                    </FormItem>
                    <FormItem
                      {...field}
                      name={[name, UpdateCategoryFieldNames.DESCRIPTION]}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={rules[UpdateCategoryFieldNames.IMAGE]}
                      label={t('label_advantage_description')}
                      noStyle
                    >
                      <TextEditor placeholder={t('label_advantage_description')} />
                    </FormItem>
                  </StyledFormListItem>
                  {fields.length > 1 ? (
                    <Button
                      style={{ width: '60%' }}
                      type="primary"
                      danger
                      size="small"
                      icon={<MinusCircleOutlined />}
                      onClick={() => remove(name)}
                    />
                  ) : null}
                </Fragment>
              ))}
              <FormItem>
                <Button
                  size="small"
                  onClick={() => add()}
                  style={{ width: '60%', marginTop: '0.5rem' }}
                  icon={<PlusOutlined />}
                >
                  {t('add_advantage')}
                </Button>
                <FormListError errors={errors} />
              </FormItem>
            </>
          )}
        </FormList>
        <FormList
          name={UpdateCategoryFieldNames.SEO_BLOCK}
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 1) {
                  return Promise.reject(new Error('At least 1 description'));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name, ...field }) => (
                <Fragment key={key}>
                  <StyledFormListItem required={false} label={t('label_seo_block', { index: key + 1 })}>
                    <FormItem
                      {...field}
                      name={[name, UpdateCategoryFieldNames.TITLE]}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={rules[UpdateCategoryFieldNames.IMAGE]}
                    >
                      <Input placeholder={t('label_title')} style={{ width: '60%' }} />
                    </FormItem>
                    <FormItem
                      {...field}
                      name={[name, UpdateCategoryFieldNames.DESCRIPTION]}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={rules[UpdateCategoryFieldNames.IMAGE]}
                      label={t('label_description')}
                      noStyle
                    >
                      <TextEditor placeholder={t('label_description')} />
                    </FormItem>
                  </StyledFormListItem>
                  {fields.length > 1 ? (
                    <Button
                      style={{ width: '60%' }}
                      type="primary"
                      danger
                      size="small"
                      icon={<MinusCircleOutlined />}
                      onClick={() => remove(name)}
                    />
                  ) : null}
                </Fragment>
              ))}
              <FormItem>
                {fields.length < 3 && (
                  <Button
                    size="small"
                    onClick={() => add()}
                    style={{ width: '60%', marginTop: '0.5rem' }}
                    icon={<PlusOutlined />}
                  >
                    {t('add_seo_block')}
                  </Button>
                )}
                <FormListError errors={errors} />
              </FormItem>
            </>
          )}
        </FormList>
      </div>
      <Button type="primary" block size="large" htmlType="submit" loading={loading}>
        {t('btn_save')}
      </Button>
    </StyledForm>
  );
};

export default UpdateCategoryForm;
