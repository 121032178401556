import { Collapse, List, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import { VariableDetectorCollapse } from './VariableDetector.style';
import { VariableInfo } from './VariableDetector.typedefs';

const { Paragraph } = Typography;

export interface VariableDetectorProps {
  variables: VariableInfo[];
  onSelectVariable: (variable: VariableInfo) => void;
}

export const VariableDetector: React.FC<VariableDetectorProps> = ({ variables, onSelectVariable }) => {
  const { t } = useTranslation(['common']);

  if (!variables.length) {
    return null;
  }

  const groupedVariables = variables.reduce(
    (acc, variable) => {
      if (!acc[variable.inputName]) {
        acc[variable.inputName] = [];
      }
      acc[variable.inputName].push(variable);
      return acc;
    },
    {} as Record<string, VariableInfo[]>,
  );

  return (
    <VariableDetectorCollapse
      items={[
        {
          key: 'variables',
          label: `${t('variables_detector_title')}: ${variables.length}`,
          children: (
            <>
              <Paragraph>{t('variables_detector_hint')}</Paragraph>
              <Collapse
                size="small"
                items={Object.keys(groupedVariables).map((inputName) => ({
                  key: inputName,
                  label: `Input: ${inputName}`,
                  children: (
                    <List
                      size="small"
                      dataSource={groupedVariables[inputName]}
                      renderItem={(variable, index) => (
                        <List.Item>
                          <Button size="small" type="dashed" onClick={() => onSelectVariable(variable)}>
                            {index + 1}. {variable.value}
                          </Button>
                        </List.Item>
                      )}
                    />
                  ),
                }))}
              />
            </>
          ),
        },
      ]}
    />
  );
};
