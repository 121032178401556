// Globals
import { useTranslation } from 'react-i18next';
import { Color } from 'antd/es/color-picker';
import { CSSProperties, memo, useCallback, useMemo } from 'react';

// Models
import { ISetting } from '@/models/settings.model';

// Helpers
import { getFooterLinks, IFooterLink } from '../../helpers/footerConfig';

// Components
import { PhoneFilledIcon } from '@/icons';
import {
  StyledBottomBlock,
  StyledColumn,
  StyledColumnsWrapper,
  StyledContactsBlock,
  StyledContainer,
  StyledFooter,
  StyledImageHolder,
  StyledLinks,
  StyledMainBlock,
  StyledPhoneLink,
  StyledSubColumn,
  StyledText,
  StyledTitle,
} from './ExampleFooterStyles';

type IExampleFooter = {
  settings: ISetting;
  backgroundColor?: Color;
  textColor?: Color;
  image?: string;
};

const ExampleFooter = ({ settings, backgroundColor, textColor, image }: IExampleFooter) => {
  const { t } = useTranslation(['common']);
  const linksConfig = useMemo(() => getFooterLinks(t), [t]);
  const renderLinks = useCallback(
    (links: IFooterLink[]) =>
      links.map((item) => (
        <li key={item.label}>
          <StyledLinks href={item.route}>{item.label}</StyledLinks>
        </li>
      )),
    [],
  );

  const style = {
    color: textColor?.toHexString?.() || textColor,
    backgroundColor: backgroundColor?.toHexString?.() || backgroundColor,
  } as CSSProperties;

  return (
    <StyledFooter style={style}>
      <StyledContainer>
        <StyledMainBlock>
          <StyledImageHolder>
            <img src={image ?? settings?.images?.find(({ metaType }) => metaType === 'footerLogo')?.file?.url} alt="" />
          </StyledImageHolder>
          <StyledColumnsWrapper>
            {linksConfig.map(({ links, title }) => (
              <StyledColumn key={title}>
                <li>
                  <StyledTitle>{title}</StyledTitle>
                  <StyledSubColumn>{renderLinks(links)}</StyledSubColumn>
                </li>
              </StyledColumn>
            ))}
          </StyledColumnsWrapper>
        </StyledMainBlock>
        <StyledBottomBlock>
          <StyledContactsBlock>
            <PhoneFilledIcon />
            <div>
              <StyledText>{t('footer_phone_title')}</StyledText>
              <StyledPhoneLink href={`tel:${settings?.phones.footer}`}>{settings?.phones.footer}</StyledPhoneLink>
            </div>
          </StyledContactsBlock>
          <StyledText>{settings?.copyright}</StyledText>
        </StyledBottomBlock>
      </StyledContainer>
    </StyledFooter>
  );
};

export default memo(ExampleFooter);
