import { IUploadFileDto, IUploadFileResponse, staticUploadEndpoints } from '@/models/upload.model';
import { ApiResponse, convertModelToFormData, httpClient } from '@/utils';

class UploadAPI {
  static uploadFile(data: IUploadFileDto): ApiResponse<IUploadFileResponse> {
    const { UPLOAD_FILE } = staticUploadEndpoints;
    return httpClient.post<IUploadFileResponse>(UPLOAD_FILE, convertModelToFormData(data, new FormData(), '', true));
  }
}

export default UploadAPI;
