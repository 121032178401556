// Globals
import { InfoCircleOutlined } from '@ant-design/icons';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Form, FormItem } from '@/components';
import { Checkbox, Input, Select } from '@/ui';

// Helpers
import { CreateChannelFormFields, getChannelsOptions, TCreateChannelFormValues } from '../../helpers';
import { useChannelFormRules } from '../../hooks';

// Models
import { ChannelType, IChannel } from '@/models/channel.model';
import { StyledFormItem } from '@/modules/Faq/components/FaqForm/FaqFormStyles';
import { ChannelTypeFilterValues } from '../../helpers/types';

type TChannelFormProps = {
  onSubmit: (values: TCreateChannelFormValues) => void;
  children?: React.ReactNode;
  initialValues?: Partial<TCreateChannelFormValues>;
  channels?: IChannel[] | null;
  isCreating?: boolean;
};

const ChannelForm = ({ onSubmit, children, initialValues, channels, isCreating }: TChannelFormProps) => {
  const rules = useChannelFormRules();
  const { t } = useTranslation(['common']);
  const options = useMemo(() => getChannelsOptions(channels), [channels]);
  const [channelType, setChannelType] = useState<string>(
    initialValues?.[CreateChannelFormFields.TYPE] ?? ChannelType.PRODUCTS,
  );

  return (
    <Form onFinish={onSubmit} initialValues={initialValues}>
      <FormItem
        name={CreateChannelFormFields.NAME}
        label={t('channel_name')}
        rules={rules[CreateChannelFormFields.NAME]}
      >
        <Input placeholder={t('channel_name_placeholder')} />
      </FormItem>
      <FormItem
        name={CreateChannelFormFields.SLUG}
        label={t('channel_code')}
        rules={rules[CreateChannelFormFields.SLUG]}
        tooltip={{
          title: t('channel_code_help'),
          icon: <InfoCircleOutlined />,
        }}
      >
        <Input disabled={!isCreating} placeholder={t('channel_slug_placeholder')} />
      </FormItem>
      <FormItem
        name={CreateChannelFormFields.TYPE}
        label={t('channel_type')}
        rules={rules[CreateChannelFormFields.TYPE]}
      >
        <Select
          disabled={!isCreating}
          options={ChannelTypeFilterValues.filter((type) => type.value !== '')}
          onChange={(value) => setChannelType(value)}
        />
      </FormItem>
      {channelType === ChannelType.PARTS && (
        <StyledFormItem
          layout="horizontal"
          name={CreateChannelFormFields.HIDE_SERIAL_NUMBER}
          label={t('channel_hide_serial_number')}
          valuePropName="checked"
        >
          <Checkbox />
        </StyledFormItem>
      )}
      <FormItem
        name={CreateChannelFormFields.SITE_URL}
        label={t('channel_url')}
        rules={rules[CreateChannelFormFields.SITE_URL]}
      >
        <Input placeholder={t('channel_url_placeholder')} />
      </FormItem>
      {channels && (
        <FormItem
          name={CreateChannelFormFields.COPY_CHANNEL_ID}
          label={t('channel_copy')}
          rules={rules[CreateChannelFormFields.COPY_CHANNEL_ID]}
        >
          <Select options={options} placeholder={t('channel_copy_placeholder')} />
        </FormItem>
      )}
      {children}
    </Form>
  );
};

export default ChannelForm;
